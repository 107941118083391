@tailwind base;
@layer base {
	img {
		@apply inline-block;
	}
}
@tailwind components;
@tailwind utilities;

@font-face {
	font-family: "Cubano";
	src: url(./assets/fonts/Cubano.ttf);
}

body {
	margin: 0;
	font-family: "Cubano", "Roboto", "Helvetica Neue", sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-weight: 400;
	background-color: "#fff";
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}
